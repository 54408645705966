h2 a {
  color: #23a2dc;
  text-decoration: none;
}

.category a:hover {
  color: #1C687D;
}

ul.category {
  padding-left: 1em;
  padding-right: 3em;
}

.content img {
  padding-bottom: 20px;
}

.two-columns {
  padding-bottom: 20px;
}

#sidebar .category, .footer-nav, .footer-bottom .social-networks, .state ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.advanced-search {
  padding-left: 9999px;
  padding-right: 9999px;
  margin-left: -9999px;
  margin-right: -9999px;
}

* {
  margin: 0;
  padding: 0;
  max-height: 100000px;
}

ul, ol, dl, p, h1, h2, h3, h4, h5, h6, address, form, table, blockquote, applet, embed, object, iframe, frameset {
  margin: 0 0 14px;
}

html, body, div, span, applet, embed, object, iframe, frameset,
p, q, blockquote, sub, sup, a, abbr, acronym, font, img, small, center,
h1, h2, h3, h4, h5, h6, b, strong, big, u, ins,
i, cite, em, var, dfn, address, s, strike, del,
dl, dt, dd, ol, ul, li, pre, code, kbd, samp, tt,
fieldset, legend, form, label, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, footer, header,
hgroup, menu, nav, section, summary, time, mark, audio, video {
  border: 0;
  background: none;
}

body {
  color: #666666;
  background: white;
}

a {
  text-decoration: underline;
  color: #6b3c96;
  outline: none;
}
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

video, iframe, object, embed {
  max-width: 100%;
}

input, textarea, select {
  color: #666666;
  vertical-align: middle;
  overflow: auto;
}

label {
  vertical-align: middle;
}

button, input {
  width: auto;
  overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, b, strong, th {
  font-weight: bold;
}

h1 {
  font-size: 200%;
}

h2 {
  font-weight: bold;
  text-transform: capitalize;
  color: #23a2dc;
  font-size: 150%;
}

h3 {
  font-size: 120%;
}

h4 {
  font-size: 110%;
}

h5 {
  font-size: 100%;
}

h6 {
  font-size: 90%;
}

i, cite, em, var, dfn, address {
  font-style: italic;
}

u, ins {
  text-decoration: underline;
}

s, strike, del {
  text-decoration: line-through;
}

blockquote, blockquote:before, blockquote:after, q, q:before, q:after {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
}

blockquote {
  border-left: 4px solid #ccc;
  padding-left: 10px;
  margin-left: 14px;
}

cite {
  display: block;
  text-align: right;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  border: 1px solid #ccc;
  padding: 3px 7px;
  text-align: left;
  vertical-align: top;
}

th {
  border-color: #999;
  text-align: center;
  vertical-align: middle;
}

ul {
  margin-left: 1px;
}

ul, ol {
  padding-left: 3em;
}
ul ul, ul ol, ol ul, ol ol {
  margin: 0;
}

ul li, ol li {
  list-style-position: inside;
}

ul > li {
  list-style-type: disc;
}

dl dt {
  float: left;
  clear: left;
  padding-right: 4px;
  font-weight: bold;
}

.widget {
  margin-bottom: 14px;
  overflow: hidden;
}
.widget ul {
  padding-left: 2em;
}
.widget select {
  min-width: 150px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  background: #fff;
  margin: 0;
  padding: 3px 7px;
  border: 2px solid #666;
}

.comment-form input[type="text"] {
  margin: 0 4px 0 0;
  width: 300px;
  border: 2px solid #666;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  color: #303030;
}
.comment-form label {
  display: block;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  text-transform: uppercase;
  color: #303030;
}
.comment-form label .required {
  color: #ff1d25;
}
.comment-form textarea {
  width: 300px;
  overflow: auto;
  height: 120px;
  resize: none;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  border: 2px solid #666;
}

.searchform input[type="text"] {
  width: 200px;
  margin: 0 4px 0 0;
}

.post-password-form input[type="password"] {
  margin: 0 4px 0 0;
  width: 150px;
}

.commentlist {
  margin: 0 0 14px;
}

.commentlist .edit-link {
  margin: 0;
}

.commentlist-item .commentlist-item {
  padding: 0 0 0 3em;
}

@media only screen and (max-width: 767px) {
  .commentlist-item .commentlist-item {
    padding: 0;
  }
}
.comment,
.commentlist-holder {
  overflow: hidden;
}

.commentlist-item .commentlist-item,
.commentlist-item + .commentlist-item {
  padding-top: 14px;
}

.commentlist .avatar-holder {
  float: left;
  margin: 0 14px 4px 0;
}

.gallery-item dt {
  float: none;
  padding: 0;
}

.navigation {
  overflow: hidden;
}
.navigation .next {
  float: left;
}
.navigation .prev {
  float: right;
}

.wp-caption {
  max-width: 100%;
  clear: both;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 5px;
}
.wp-caption p {
  margin: 10px 0 0;
}

div.aligncenter {
  margin: 0 auto 14px;
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignleft {
  float: left;
  margin: 0 24px 24px 0;
}

.alignright {
  float: right;
  margin: 0 0 24px 24px;
}

input[type="submit"] {
  border-radius: 0;
  border: 2px solid #3fa4f0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 6px 10px;
  background: #3fa4f0;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  input[type="submit"] {
    padding: 4px 10px;
    font-size: 12px;
    line-height: 15px;
  }
}
input[type="submit"]:hover {
  border-color: #5fb8f4;
  background: #5fb8f4;
}

.post-password-form label {
  margin-bottom: 0;
}
.post-password-form input[type="submit"] {
  display: inline-block;
  vertical-align: middle;
}

* {
  font-size: 100.01%;
}

html {
  font-size: 62.5%;
}

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?-kzjnf2");
  src: url("fonts/icomoon.eot?#iefix-kzjnf2") format("embedded-opentype"), url("fonts/icomoon.woff?-kzjnf2") format("woff"), url("fonts/icomoon.ttf?-kzjnf2") format("truetype"), url("fonts/icomoon.svg?-kzjnf2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url("fonts/2C6B54_0_0.eot");
  src: url("fonts/2C6B54_0_0.eot?#iefix") format("embedded-opentype"), url("fonts/2C6B54_0_0.woff2") format("woff2"), url("fonts/2C6B54_0_0.woff") format("woff"), url("fonts/2C6B54_0_0.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url("fonts/2C6B54_1_0.eot");
  src: url("fonts/2C6B54_1_0.eot?#iefix") format("embedded-opentype"), url("fonts/2C6B54_1_0.woff2") format("woff2"), url("fonts/2C6B54_1_0.woff") format("woff"), url("fonts/2C6B54_1_0.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url("fonts/2C6B54_2_0.eot");
  src: url("fonts/2C6B54_2_0.eot?#iefix") format("embedded-opentype"), url("fonts/2C6B54_2_0.woff2") format("woff2"), url("fonts/2C6B54_2_0.woff") format("woff"), url("fonts/2C6B54_2_0.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url("fonts/2C6B54_3_0.eot");
  src: url("fonts/2C6B54_3_0.eot?#iefix") format("embedded-opentype"), url("fonts/2C6B54_3_0.woff2") format("woff2"), url("fonts/2C6B54_3_0.woff") format("woff"), url("fonts/2C6B54_3_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-size: 15px;
  line-height: 17px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  min-width: 980px;
}
@media only screen and (max-width: 980px) {
  body {
    min-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  body {
    min-width: 100%;
  }
}

img {
  vertical-align: top;
}

input[type=submit]:hover {
  cursor: pointer;
}

input[type=submit]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type=image] {
  padding: 0;
  border: none;
}

input[type=text],
input[type=password],
input[type=submit],
input[type=file],
input[type=search],
input[type=email],
input[type=tel],
input[type=url],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

a:active {
  background-color: transparent;
}

input,
textarea,
select {
  font: 100% 'din', Arial, Verdana, Helvetica, sans-serif;
  vertical-align: middle;
  color: #000;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pdf:before {
  content: "\e600";
}

.icon-twitter:before {
  content: "\e601";
}

.icon-facebook:before {
  content: "\e602";
}

.icon-linkedin:before {
  content: "\e603";
}

.icon-play:before {
  content: "\e604";
}

.icon-youtube:before {
  content: "\e605";
}

.icon-logo:before {
  content: "\e606";
}

.icon-logo-ci:before {
  content: "\e607";
}

body#home h1 {
  margin-bottom: 8px;
  font-size: 1.8571em;
  line-height: 1em;
  font-weight: 100;
  text-transform: uppercase;
  color: #a9cc48;
}

@media only screen and (max-width: 767px) {
  body#home h1 {
    margin-bottom: 3px;
    font-size: 18px;
    line-height: 16px;
  }
}
body#home h1 .title {
  text-transform: none;
  display: block;
  font-weight: 300;
  margin-bottom: 8px;
}
body#home h1:after {
  display: none;
}

blockquote {
  line-height: 1.1em;
}

#wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

#header {
	background: white;
	padding-bottom: 20px;
}

.header-top {
  padding-top: 40px;
}
.header-top .container {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .header-top {
    padding-top: 20px;
  }
}

.logo {
  width: 260px;
  height: 37px;
}
.logo img {
  display: block;
}
@media only screen and (max-width: 767px) {
  .logo img {
    width: 100%;
    height: auto;
  }
}

.nav-holder {
  position: static;
}
@media only screen and (max-width: 767px) {
  .nav-holder {
    width: auto;
    float: none;
    padding: 0;
  }
  .nav-holder .container-fluid {
    padding: 0;
  }
}

#nav {
  margin-bottom: 0;
}

#nav.navbar.navbar-default.pull-right {
  left: 21px;
}

@media only screen and (max-width: 767px) {
  #nav {
    float: none !important;
    position: static;
    min-height: 0;
    margin-bottom: 11px;
  }
}
#nav .navbar-toggle {
  border: none;
  background: transparent;
  padding: 0;
  border-radius: 0;
  margin: 2px 20px 0 0;
}
#nav .navbar-toggle .icon-bar {
  background: #0070cd;
  height: 3px;
  width: 20px;
  border-radius: 3px;
}
#nav .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 3px;
}
#nav .navbar-toggle.collapsed .icon-bar {
  background: #23313a;
}
#nav .nav > li {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 300;
  border-bottom: 4px solid #d2f0fc;
}
#nav .nav > li:first-child {
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li:first-child {
    padding-left: 10px;
  }
}
@media only screen and (max-width: 767px) {
  #nav .nav > li {
    border: none;
    padding: 10px;
    font-weight: normal;
  }
  #nav .nav > li.divider {
    background: #23313a;
    height: 1px;
    margin: 10px 0;
    padding: 0;
  }
  #nav .nav > li:last-child {
    border-bottom: 1px solid #23313a;
  }
}
#nav .nav > li > a {
  text-decoration: none;
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-bottom: -4px;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li > a {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    border: none;
    padding: 0;
  }
  #nav .nav > li > a:hover {
    color: #0070cd !important;
  }
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.active a {
    color: #0070cd !important;
  }
}
#nav .nav > li.nav-about.active a, #nav .nav > li.nav-about:hover a {
  border-bottom: 4px solid #f05523;
  color: #f05523;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.nav-about.active a, #nav .nav > li.nav-about:hover a {
    border: none;
  }
}
#nav .nav > li.nav-resources.active a, #nav .nav > li.nav-resources:hover a {
  border-bottom: 4px solid #6b3c96;
  color: #6b3c96;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.nav-resources.active a, #nav .nav > li.nav-resources:hover a {
    border: none;
  }
}
#nav .nav > li.nav-directory.active a, #nav .nav > li.nav-directory:hover a {
  border-bottom: 4px solid #a8ce3c;
  color: #a8ce3c;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.nav-directory.active a, #nav .nav > li.nav-directory:hover a {
    border: none;
  }
}
#nav .nav > li.menu-item-739.active a, #nav .nav > li.menu-item-739:hover a {
  border-bottom: 4px solid #e69dd3;
  color: #e69dd3;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.menu-item-739.active a, #nav .nav > li.menu-item-739:hover a {
    border: none;
  }
}
#nav .nav > li.nav-blog.active a, #nav .nav > li.nav-blog:hover a {
  border-bottom: 4px solid #F1B923;
  color: #F1B923;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.nav-blog.active a, #nav .nav > li.nav-blog:hover a {
    border: none;
  }
}
#nav .nav > li.nav-contact.active a, #nav .nav > li.nav-contact:hover a {
  border-bottom: 4px solid #0070cd;
  color: #0070cd;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.nav-contact.active a, #nav .nav > li.nav-contact:hover a {
    border: none;
  }
}
#nav .nav > li.menu-item-1164.active a, #nav .nav > li.menu-item-1164:hover a {
  border-bottom: 4px solid #2E6294;
  color: #2E6294;
}
@media only screen and (max-width: 767px) {
  #nav .nav > li.menu-item-1164.active a, #nav .nav > li.menu-item-1164:hover a {
    border: none;
  }
}

.dropdown-menu.my-account li a {
  border: none !important;
  text-decoration: none  !important;
}

.dropdown-menu.my-account li {
  list-style: none !important;
}

#nav .nav > ul.dropdown-menu.my-account li.menu-item-739.active a.dropdown-toggle,
#nav .nav > ul.dropdown-menu.my-account li.menu-item-739:hover a.dropdown-toggle {
  border-bottom: none !important;
  text-decoration: none !important;
}

#nav .nav:after {
  display: none;
  /*content: '';*/
  /*position: absolute;*/
  /*bottom: 4px;*/
  /*left: 95%;*/
  /*height: 4px;*/
  /*background: #d2f0fc;*/
  /*width: 999px;*/
}
@media only screen and (max-width: 767px) {
  #nav .nav:after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #nav .navbar-collapse {
    width: 100%;
    background: #fff;
    z-index: 99;
    margin: 0;
  }
}

.header-bottom {
  padding-top: 86px;
  overflow: hidden;
  position: relative;
  min-height: 349px;
}
@media only screen and (max-width: 767px) {
  .header-bottom {
    padding-top: 20px;
    min-height: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .header-bottom .container {
    padding: 0;
  }
}
.header-bottom .bg-stretch {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.header-bottom .bg-stretch img {
  max-width: none;
  width: 100%;
  height: auto;
  opacity: 0;
}
.header-bottom .title-holder {
  overflow: hidden;
  margin-bottom: 9px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .header-bottom .title-holder {
    margin-bottom: 2px;
  }
}
.header-bottom .title-holder:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #daeff9 0%, #fcfeff 100%);
  background-image: -o-linear-gradient(left, #daeff9 0%, #fcfeff 100%);
  background-image: linear-gradient(to right, #daeff9 0%, #fcfeff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFDAEFF9', endColorstr='#FFFCFEFF', GradientType=1);
}
.header-bottom .title-holder .title {
  font-size: 3.5714em;
  line-height: 1em;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #23a2dc;
}
.header-bottom .title-holder .slogan {
  color: #23a2dc;
  font-size: 1.7142em;
  line-height: 0.9166em;
  display: block;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .header-bottom .title-holder .slogan {
    margin-bottom: 5px;
  }
}
.header-bottom .description {
  font-size: 1em;
  line-height: 1.2857em;
  color: #70cfe9;
  padding-right: 37%;
}
@media only screen and (max-width: 767px) {
  .header-bottom .description {
    padding-bottom: 30px;
  }
}

#main {
  width: 100%;
}

.cta-bar {
  background: #70cfe9;
}
@media only screen and (max-width: 767px) {
  .cta-bar .container {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn-group {
    width: 100%;
  }
}
.cta-bar .btn {
  text-align: left;
  text-decoration: none;
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient(110deg, #faa819, #f5821e);
  background-image: -o-linear-gradient(110deg, #faa819, #f5821e);
  background-image: linear-gradient(110deg, #faa819, #f5821e);
  border-radius: 0;
  border: none;
  position: relative;
  height: 133px;
  z-index: 5;
  padding: 40px 34px 40px 95px;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn {
    padding: 31px 10px;
    height: 96px;
    display: block;
    float: none;
  }
}
.cta-bar .btn:after {
  content: '';
  position: absolute;
  top: 0;
  right: -129px;
  border-width: 0 130px 133px 0;
  border-color: transparent transparent #f5821e;
  border-style: solid;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn:after {
    display: none;
  }
}
.cta-bar .btn:first-child {
  z-index: 9;
  border-left: 10px solid #f05523;
  padding-left: 25px;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn:first-child {
    padding: 31px 10px;
    border-left: 0;
  }
}
.cta-bar .btn:last-child:before {
  content: '';
  position: absolute;
  top: 0;
  right: -205px;
  width: 210px;
  height: 133px;
  background: url(images/bg-started.png) no-repeat;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn:last-child:before {
    background-size: 104px 96px;
    width: 104px;
    height: 96px;
    right: -102px;
  }
}
.cta-bar .btn:hover {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient(110deg, #f68b1d, #f05523);
  background-image: -o-linear-gradient(110deg, #f68b1d, #f05523);
  background-image: linear-gradient(110deg, #f68b1d, #f05523);
  z-index: 10;
}
.cta-bar .btn:hover:after {
  border-color: transparent transparent #f05523;
}
.cta-bar .btn .text-holder {
  font-size: 30px;
  line-height: 28px;
  color: #fff;
  float: left;
  padding-right: 30px;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn .text-holder {
    font-size: 21px;
    line-height: 21px;
    padding-right: 20px;
    width: 75%;
  }
}
.cta-bar .btn .text-holder .title {
  display: block;
  font-weight: bold;
}
.cta-bar .btn .text-holder .text {
  display: block;
  font-weight: 100;
  text-transform: uppercase;
}
.cta-bar .btn .btn-play {
  width: 52px;
  height: 54px;
  float: left;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn .btn-play {
    width: 37px;
    height: 39px;
  }
}
.cta-bar .btn .btn-go {
  width: 52px;
  height: 54px;
  float: left;
}
@media only screen and (max-width: 767px) {
  .cta-bar .btn .btn-go {
    width: 37px;
    height: 39px;
  }
}

.slideshow {
  background: #0070cd;
}
.slideshow .container {
  background: url(images/bg-slider.png) no-repeat 100% 0;
}
@media only screen and (max-width: 767px) {
  .slideshow .container {
    background: none;
  }
}
.slideshow .description {
  color: #d2f0fc;
  padding-top: 39px;
  margin-bottom: 55px;
}
@media only screen and (max-width: 767px) {
  .slideshow .description {
    padding-top: 15px;
    margin-bottom: 20px;
  }
}
.slideshow .description .title {
  overflow: hidden;
  margin-bottom: 23px;
  font-size: 1em;
  line-height: 1.2857em;
  text-transform: uppercase;
  color: #fff;
}
.slideshow .description .title strong {
  font-weight: bold;
}
.slideshow .description .title span {
  font-weight: 100;
}
@media only screen and (max-width: 767px) {
  .slideshow .description .title {
    margin-bottom: 11px;
  }
}
.slideshow .description .details {
  overflow: hidden;
  padding-right: 20%;
  margin-bottom: 9px;
}
@media only screen and (max-width: 767px) {
  .slideshow .description .details {
    padding-right: 0;
  }
}
.slideshow .description .details .big-text {
  color: #fff;
  font-size: 1.7142em;
  line-height: 1.25em;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .slideshow .description .details .big-text {
    font-size: 12px;
    line-height: 15px;
  }
}
.slideshow .description .details p {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .slideshow .description .details p {
    margin-bottom: 7px;
  }
}
.slideshow .description .btn {
  text-transform: uppercase;
  border: 2px solid #70cfe9;
  color: #fff;
  line-height: 14px;
}
.slideshow .description .btn:hover {
  border-color: #fff;
}
.slideshow .image-holder {
  text-align: center;
  padding-top: 20px;
}
.slideshow .carousel-indicators {
  bottom: 6px;
}
@media only screen and (max-width: 767px) {
  .slideshow .carousel-indicators {
    left: 8px;
    bottom: 0;
    margin-left: 0;
    text-align: left;
  }
}
.slideshow .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 0;
  vertical-align: top;
  margin: 0 3px;
}
@media only screen and (max-width: 767px) {
  .slideshow .carousel-indicators li {
    width: 13px;
    height: 13px;
    margin: 0 2px;
  }
}
.slideshow .carousel-indicators li.active {
  border-color: transparent;
}
.slideshow .carousel-indicators li:hover {
  border-color: transparent;
  background: #29ade1;
}

#twocolumns {
  background: url(images/bg-cloud.png) no-repeat 100% 0;
  background-image: url("images/bg-cloud.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #twocolumns {
    background-image: url("images/bg-cloud@2x.png");
    background-size: 875px 188px;
    min-height: 400px;
  }
}

body#home #twocolumns {
  background: none;
}
body#home #content {
  padding-top: 40px;
}
@media only screen and (max-width: 767px) {
  body#home #content {
    padding-top: 16px;
  }
}

#content {
  padding-top: 27px;
}
@media only screen and (max-width: 767px) {
  #content {
    height: auto !important;
    padding-top: 16px;
  }
}
#content .action {
  padding-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  #content .action {
    height: auto !important;
    padding-bottom: 15px;
  }
}
#content .action p {
  margin-bottom: 23px;
}
@media only screen and (max-width: 767px) {
  #content .action p {
    margin-bottom: 10px;
  }
}
#content .action .btn {
  border-width: 2px;
  text-transform: uppercase;
  line-height: 14px;
}
@media only screen and (max-width: 767px) {
  #content .action .btn {
    padding: 7px 10px 5px;
  }
}
#content .action .btn:hover {
  border-color: #6f943d;
  color: #6f943d;
}
#content .featuring {
  border-bottom: 9px solid #a8ce3c;
  padding-bottom: 45px;
}
@media only screen and (max-width: 767px) {
  #content .featuring {
    height: auto !important;
    padding-bottom: 21px;
    border: none;
  }
  #content .featuring:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 4px;
    background: #a8ce3c;
  }
}
#content .featuring .video-holder {
  position: relative;
  border-bottom: 9px solid #a8ce3c;
  margin-bottom: 21px;
}
@media only screen and (max-width: 767px) {
  #content .featuring .video-holder {
    border-width: 4px;
    margin-bottom: 7px;
  }
}
#content .featuring .video-holder .video-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  display: block;
  background: #a8ce3c;
  padding: 5px 9px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  #content .featuring .video-holder .video-title {
    padding: 4px 9px 1px;
  }
}
#content .featuring .video-holder img {
  width: auto;
}
@media only screen and (max-width: 767px) {
  #content .featuring .video-holder img {
    width: 100%;
  }
}
#content .featuring .video-holder .btn-play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 52px;
  height: 54px;
}
#content .featuring .video-holder .btn-play:hover {
  opacity: 0.6;
}
#content .heading {
  overflow: hidden;
  margin-bottom: 23px;
}
@media only screen and (max-width: 767px) {
  #content .heading {
    margin-bottom: 15px;
  }
}
#content .heading h2 {
  margin-bottom: 0;
  font-size: 1em;
  line-height: 1.2857em;
  font-weight: normal;
  text-transform: uppercase;
  color: #a8ce3c;
}
#content .heading .info {
  color: #575757;
}
#content .heading .info address {
  display: inline;
  font-style: normal;
}
#content p {
  margin-bottom: 17px;
}
@media only screen and (max-width: 767px) {
  #content p {
    margin-bottom: 4px;
  }
}
#content .more {
  display: block;
}
#content .more a {
  text-decoration: none;
}
#content .search-form {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  #content .search-form {
    margin-bottom: 20px;
  }
}
#content .search-form .form-group {
  position: relative;
  margin-bottom: 0;
}
#content .search-form .form-group .form-control {
  border-radius: 0;
  border: 2px solid #6b3c91;
  padding-right: 55px;
}
@media only screen and (max-width: 767px) {
  #content .search-form .form-group .form-control {
    height: 35px;
    padding: 5px 35px 5px 5px;
  }
}
#content .search-form .form-group .glyphicon {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 24px;
  line-height: 1px;
  color: #6b3c91;
}
@media only screen and (max-width: 767px) {
  #content .search-form .form-group .glyphicon {
    font-size: 18px;
    top: 15px;
    right: 10px;
  }
}
#content .intro-block {
  color: #303030;
  margin-bottom: 26px;
}
#content .intro-block h2 {
  font-size: 1.7142em;
  line-height: 1.1665em;
  font-weight: 300;
  color: #239dd7;
  margin-bottom: 8px;
}
#content .intro-block p {
  margin-bottom: 19px;
}
#content .tab-wrapper .nav {
  margin: 0;
  border-bottom: 4px solid #cdebf7;
  display: inline-block;
  vertical-align: top;
}
@media only screen and (max-width: 767px) {
  #content .tab-wrapper .nav {
    display: block;
  }
}
#content .tab-wrapper .nav li {
  margin: 0 40px -4px 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 7px;
  float: left;
}
@media only screen and (max-width: 767px) {
  #content .tab-wrapper .nav li {
    float: none;
    margin: 0 0 10px;
    padding: 0 0 0 5px;
  }
}
#content .tab-wrapper .nav li a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #23313a;
  line-height: 1.42857;
  border: 1px solid transparent;
  border-radius: 0;
}
@media only screen and (max-width: 767px) {
  #content .tab-wrapper .nav li a {
    display: inline-block;
    vertical-align: top;
  }
}
#content .tab-wrapper .nav li a:hover {
  color: #6b3c96;
}
#content .tab-wrapper .nav li.active {
  border-bottom: 4px solid #6b3c96;
}
@media only screen and (max-width: 767px) {
  #content .tab-wrapper .nav li.active {
    border-width: 0 0 0 3px;
    border-color: transparent transparent transparent #6b3c96;
    border-style: solid;
    padding-left: 2px;
  }
}
#content .tab-wrapper .nav li.active a {
  color: #6b3c96;
}
#content .tab-wrapper .tab-content {
  padding: 13px 0 46px;
}
#content .tab-wrapper .tab-content .count {
  display: block;
  color: #666;
  font-size: 0.8571em;
  line-height: 1.25em;
  margin-bottom: 37px;
}
#content .tab-wrapper .tab-content .research-box {
  color: #303030;
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  #content .tab-wrapper .tab-content .research-box {
    height: auto !important;
    padding-bottom: 35px;
    margin-bottom: 30px;
  }
}
#content .tab-wrapper .tab-content .research-box:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 10px;
  background: #6b3c96;
}
#content .tab-wrapper .tab-content .research-box .left-column img {
  width: 100%;
  height: auto;
  margin-bottom: 11px;
}
#content .tab-wrapper .tab-content .research-box .left-column .download {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  display: block;
  position: relative;
  margin-bottom: 20px;
}
#content .tab-wrapper .tab-content .research-box .left-column .download a {
  color: #6b3c96;
  text-decoration: none;
  padding-left: 30px;
}
#content .tab-wrapper .tab-content .research-box .left-column .download a:hover {
  color: #93c;
}
#content .tab-wrapper .tab-content .research-box .left-column .download .icon-pdf {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
}
#content .tab-wrapper .tab-content .research-box h2 {
  font-size: 1.1428em;
  line-height: 1.125em;
  margin-bottom: 11px;
}
#content .tab-wrapper .tab-content .research-box h2 a {
  color: #6b3c96;
  text-decoration: none;
}
#content .tab-wrapper .tab-content .research-box h2 a:hover {
  color: #93c;
}
#content .tab-wrapper .tab-content .research-box .published {
  display: block;
  margin-bottom: 9px;
}
#content .tab-wrapper .tab-content .research-box p {
  margin-bottom: 11px;
}
#content .tab-wrapper .tab-content .research-box .more {
  display: block;
}
#content .tab-wrapper .tab-content .research-box .more a {
  color: #303030;
}

#sidebar {
  background: #f4f2f2;
  border-left: 1px solid #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 0 1px #dcd0e6;
  box-shadow: 0 1px 0 1px #dcd0e6;
}
@media only screen and (max-width: 767px) {
  #sidebar {
    height: auto !important;
    padding-top: 16px;
    padding-bottom: 18px;
  }
}
#sidebar:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  background: #f4f2f2;
  width: 999px;
  height: 100%;
}
#sidebar:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -2px;
  right: 0;
  height: 9px;
  background: #6b3c96;
}
@media only screen and (max-width: 767px) {
  #sidebar:after {
    height: 4px;
  }
}
#sidebar h2 {
  margin: 0 0 8px -12px;
  min-height: 30px;
  background: #6b3c96;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ececec;
  padding: 7px 6px 5px 20px;
}
@media only screen and (max-width: 767px) {
  #sidebar h2 {
    min-height: 16px;
    margin: 0 0 5px;
  }
}
#sidebar p {
  margin-bottom: 8px;
}
#sidebar .category {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  #sidebar .category {
    margin-bottom: 10px;
  }
}
#sidebar .category li {
  list-style: none;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 7px 0;
  border-bottom: 1px dotted #bfbfbf;
}
@media only screen and (max-width: 767px) {
  #sidebar .category li {
    padding: 10px 0 7px;
  }
}

#sidebar .category li a {
  color: #6b3c96;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 28px;
}
@media only screen and (max-width: 767px) {
  #sidebar .category li a {
    padding-left: 12px;
  }
}
#sidebar .category li a:hover {
  color: #43266a;
}
#sidebar .category li a span {
  margin-right: 10px;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 767px) {
  #sidebar .category li a span {
    top: 3px;
    font-size: 10px;
  }
}
#sidebar .btn {
  text-transform: uppercase;
  border: 2px solid #6b3c96;
  color: #6b3c96;
  line-height: 14px;
}
@media only screen and (max-width: 767px) {
  #sidebar .btn {
    padding: 7px 10px 5px;
  }
}
#sidebar .btn:hover {
  border-color: #43266a;
  color: #43266a;
}

#footer {
  position: relative;
  overflow: hidden;
}

.footer-top {
  background: #0070cd;
  padding-top: 15px;
}
@media only screen and (max-width: 767px) {
  .footer-top {
    padding-top: 10px;
  }
}
.footer-top .logo {
  width: 192px;
  height: 28px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .footer-top .logo {
    margin-bottom: 25px;
  }
  .footer-top .logo img {
    width: 100%;
    height: auto;
  }
}
.footer-top .logo-ci {
  width: 122px;
  height: 72px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .footer-top .logo-ci {
    margin: 0 0 20px;
  }
}
.footer-top .logo-ci img {
  display: block;
  width: 72px;
  height: 72px;
}
@media only screen and (max-width: 767px) {
  .footer-top .logo-ci img {
    width: 100%;
    height: auto;
  }
}

.footer-nav {
  overflow: hidden;
}
.footer-nav li {
  list-style: none;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  text-transform: uppercase;
  float: left;
  margin: 0 30px 17px 0;
}
.footer-nav li a {
  color: #fff;
  text-decoration: none;
}
.footer-nav li a:hover {
  opacity: 0.5;
}

.footer-bottom {
  background: #0a3e6a;
  overflow: hidden;
}
.footer-bottom .copyright {
  color: #fff;
  display: block;
  font-size: 10px;
  line-height: 14px;
  padding: 15px 0 11px;
}
.footer-bottom .copyright a {
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}
.footer-bottom .copyright a:hover {
  text-decoration: underline;
}
.footer-bottom .more-info {
  background: #fff;
  font-size: 10px;
  line-height: 12px;
  font-weight: normal;
  color: #0070cd;
}
.footer-bottom .more-info span {
  display: block;
  padding: 5px 0;
}
.footer-bottom .more-info a {
  font-weight: bold;
  text-decoration: none;
  color: #0070cd;
}
.footer-bottom .more-info a:hover {
  text-decoration: underline;
}
.footer-bottom .social-networks {
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  position: relative;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .footer-bottom .social-networks {
    text-align: left !important;
  }
}
.footer-bottom .social-networks:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  background: #016bc3;
  width: 999px;
  height: 100%;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .footer-bottom .social-networks:before {
    display: none;
  }
}
.footer-bottom .social-networks li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1px;
  letter-spacing: 0;
  position: relative;
  z-index: 5;
}
.footer-bottom .social-networks li.facebook a {
  background: #08487e;
}
.footer-bottom .social-networks li.twitter a {
  background: #065292;
}
.footer-bottom .social-networks li.youtube a {
  background: #045ca5;
}
.footer-bottom .social-networks li.linkedin a {
  background: #0266b9;
}
.footer-bottom .social-networks li a {
  color: #fff;
  text-decoration: none;
  width: 39px;
  height: 40px;
  display: block;
  text-align: center;
}
.footer-bottom .social-networks li a:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.footer-bottom .social-networks li a:hover {
  background: #036;
}
.footer-bottom .social-networks li a span {
  display: inline-block;
  vertical-align: middle;
}
.footer-bottom .social-networks li .stButton {
  display: none !important;
}

h1 {
  overflow: hidden;
  margin-bottom: 39px;
  position: relative;
  font-size: 2.3em;
  line-height: 1em;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  font-weight: bold;
  color: #a9cc48;
}
@media only screen and (max-width: 767px) {
  h1 {
    margin-bottom: 20px;
  }
}
h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #daeff9 0%, #fcfeff 100%);
  background-image: -o-linear-gradient(left, #daeff9 0%, #fcfeff 100%);
  background-image: linear-gradient(to right, #daeff9 0%, #fcfeff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFDAEFF9', endColorstr='#FFFCFEFF', GradientType=1);
}

body > .jcf-select-drop {
  position: absolute;
  margin: -2px 0 0;
  z-index: 9999;
}

body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0;
}

.contact-block {
  margin-bottom: 76px;
}
.contact-block .contact-form select {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .contact-block .contact-form {
    margin-bottom: 30px;
  }
}
.contact-block .contact-form .label-holder {
  padding-top: 10px;
}
@media only screen and (max-width: 767px) {
  .contact-block .contact-form .label-holder {
    text-align: left !important;
  }
}
.contact-block .contact-form .label-holder label {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  text-transform: uppercase;
  color: #303030;
}
.contact-block .contact-form .label-holder label .required {
  color: #ff1d25;
}
.contact-block .contact-form .form-control {
  border-width: 2px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  color: #303030;
}
.contact-block .contact-form .jcf-select,
#content .jcf-select {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 2px solid #666;
  background: #fff;
  margin: 0;
  min-width: 150px;
  height: 38px;
  /*width: 100%;*/
  width: 250px;
}
.contact-block .contact-form .jcf-select select,
#content .jcf-select select {
  z-index: 1;
  left: 0;
  top: 0;
}
.contact-block .contact-form .jcf-select .jcf-select-text,
#content .jcf-select .jcf-select-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  font-size: 14px;
  line-height: 36px;
  font-weight: bold;
  text-transform: uppercase;
  color: #303030;
  margin: 0 44px 0 8px;
}
.contact-block .contact-form .jcf-select .jcf-select-opener,
#content .jcf-select .jcf-select-opener {
  position: absolute;
  text-align: center;
  background: #fff;
  width: 44px;
  bottom: 0;
  right: 0;
  top: 0;
}
.contact-block .contact-form .jcf-select .jcf-select-opener:after,
#content .jcf-select .jcf-select-opener:after {
  content: '';
  position: absolute;
  top: 11px;
  right: 11px;
  border-width: 15px 11px 0;
  border-color: #3fa4f0 transparent transparent;
  border-style: solid;
  height: 0;
  width: 0;
}
.contact-block .contact-form .textarea-required {
  display: block;
  border-bottom: 10px solid #0070cd;
  margin-bottom: 5px;
}
.contact-block .contact-form textarea {
  resize: none;
  height: 226px;
}
.contact-block .contact-form .btn-default {
  border-radius: 0;
  border-width: 2px;
  border-color: #3fa4f0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 13px 29px;
}
@media only screen and (max-width: 767px) {
  .contact-block .contact-form .btn-default {
    padding: 8px 20px;
  }
}
.contact-block .contact-form .btn-default:hover {
  border-color: #5fb8f4;
}
.contact-block .contact-form .btn-default[type="submit"] {
  background: #3fa4f0;
  color: #fff;
}
.contact-block .contact-form .btn-default[type="submit"]:hover {
  background: #5fb8f4;
}
.contact-block .contact-form .btn-default[type="reset"] {
  background: #fff;
  color: #3fa4f0;
}
.contact-block .contact-form .btn-default[type="reset"]:hover {
  color: #5fb8f4;
}
.contact-block .contact-info {
  font-size: 1em;
  line-height: 1.2857em;
  color: #303030;
}
.contact-block .contact-info .block {
  border-bottom: 4px solid #0070cd;
  padding-bottom: 4px;
  margin-bottom: 8px;
}
.contact-block .contact-info .block.add {
  border-width: 10px;
  padding-bottom: 17px;
  margin-bottom: 26px;
}
.contact-block .contact-info .block .title {
  display: block;
}
.contact-block .contact-info .block address {
  font-style: normal;
  margin: 0;
}
.contact-block .contact-info .block span {
  display: block;
}
.contact-block .contact-info .block span a {
  color: #303030;
}
.contact-block .contact-info .block span.tel-link a {
  text-decoration: none;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .contact-block .contact-info .block span.tel-link a {
    pointer-events: auto;
  }
  .contact-block .contact-info .block span.tel-link a:hover {
    text-decoration: underline;
  }
}
.contact-block .contact-info .btn-default {
  border-width: 2px;
  border-color: #3fa4f0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  text-transform: uppercase;
  background: #fff;
  color: #3fa4f0;
  text-decoration: none;
  padding: 7px 8px 5px;
}
.contact-block .contact-info .btn-default:hover {
  border-color: #5fb8f4;
  color: #5fb8f4;
}

.jcf-select-drop .jcf-option-hideme {
  display: none !important;
}

.jcf-select .jcf-select-drop {
  position: absolute;
  margin-top: 0;
  z-index: 9999;
  top: 100%;
  left: -1px;
  right: -1px;
}

.jcf-select .jcf-drop-flipped {
  bottom: 100%;
  top: auto;
}

.jcf-select-drop .jcf-select-drop-content {
  border: 2px solid #666;
}
.jcf-select-drop .jcf-select-drop-content:after {
  content: '';
  position: absolute;
  top: 0;
  left: 2px;
  right: 2px;
  height: 2px;
  background: #e0e0e0;
}

.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px;
}

.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #fff;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  color: #303030;
}

.jcf-list .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  overflow: auto;
  width: 100%;
}

.jcf-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.jcf-list ul li {
  overflow: hidden;
  display: block;
}

.jcf-list .jcf-overflow {
  overflow: auto;
}

.jcf-list .jcf-option {
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  padding: 5px 9px;
  color: #303030;
  height: 1%;
}

.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  color: #0070cd;
  position: relative;
}
.jcf-select-drop .jcf-hover:after,
.jcf-list-box .jcf-selected:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #0370cb;
}

.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: bold;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px;
}

.jcf-disabled {
  background: #ddd !important;
}

.jcf-focus, .jcf-focus * {
  border-color: #666 !important;
}

.advanced-search {
  background: #0070c8 url(images/bg-search.jpg) no-repeat 50% 0;
  background-image: url("images/bg-search.jpg");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .advanced-search {
    background-image: url("images/bg-search@2x.jpg");
    background-size: 1250px 210px;
  }
}
.advanced-search .ad-search-form {
  margin-bottom: 0;
  padding: 69px 0;
}
@media only screen and (max-width: 767px) {
  .advanced-search .ad-search-form {
    padding: 20px 0;
  }
}
.advanced-search .ad-search-form .form-group {
  margin-bottom: 0;
  position: relative;
}
.advanced-search .ad-search-form .form-group .form-control {
  height: 72px;
  border: none;
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
  text-transform: uppercase;
  color: #3fa4f0;
  padding: 18px 70px 18px 20px;
}
.advanced-search .ad-search-form .form-group .form-control::-moz-placeholder {
  color: #3fa4f0;
  opacity: 1;
}
.advanced-search .ad-search-form .form-group .form-control:-ms-input-placeholder {
  color: #3fa4f0;
}
.advanced-search .ad-search-form .form-group .form-control::-webkit-input-placeholder {
  color: #3fa4f0;
}
@media only screen and (max-width: 767px) {
  .advanced-search .ad-search-form .form-group .form-control {
    height: 50px;
    font-size: 18px;
    line-height: 18px;
    padding: 13px 45px 13px 13px;
  }
}
.advanced-search .ad-search-form .form-group .glyphicon {
  font-size: 40px;
  position: absolute;
  top: 15px;
  right: 18px;
  color: #3fa4f0;
}
@media only screen and (max-width: 767px) {
  .advanced-search .ad-search-form .form-group .glyphicon {
    font-size: 20px;
  }
}
.advanced-search .ad-search-form .link {
  display: block;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 28px 0;
}
@media only screen and (max-width: 767px) {
  .advanced-search .ad-search-form .link {
    padding: 18px 0;
  }
}
.advanced-search .ad-search-form .link a {
  color: #fff;
}

.state {
  background: #e7e7e7;
  padding: 31px 0 59px;
  border-bottom: 10px solid #9cc526;
  margin-bottom: 78px;
}
@media only screen and (max-width: 767px) {
  .state {
    padding: 20px 0;
    margin-bottom: 30px;
  }
}
.state .title {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #9cc526;
  padding: 0 10px 6px;
  border-bottom: 10px solid #9cc526;
  min-width: 150px;
  margin-bottom: 33px;
}
.state ul {
  margin-bottom: 52px;
}
@media only screen and (max-width: 767px) {
  .state ul {
    margin-bottom: 20px;
  }
}
.state ul li {
  font-size: 14px;
  line-height: 18px;
  list-style: none;
}
.state ul li a {
  color: #303030;
  text-decoration: none;
}
.state ul li a:hover {
  opacity: 0.8;
}
.state .view {
  display: block;
  padding: 0 10px;
}
.state .view a {
  color: #303030;
}
.state .view a:hover {
  text-decoration: underline;
  opacity: 0.8;
}

.videos-section {
  margin-top: 50px;
}

.video-block h2 {
  font-size: 1.7142em;
  line-height: 1.16665em;
  font-weight: 300;
  color: #239dd7;
  margin-bottom: 34px;
}
.video-block .videos-inner {
  font-size: 0;
  line-height: 0;
}
.video-block .profile {
  color: #303030;
  margin: 0 -3px 21px 0;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 14px;
  line-height: 18px;
}
.video-block .profile .preview {
  border-bottom: 10px solid #9cc526;
  position: relative;
  margin-bottom: 10px;
}
.video-block .profile .preview img {
  width: 100%;
  height: auto;
}
.video-block .profile .preview .btn-play {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 52px;
  height: 54px;
  margin: auto;
}
.video-block .profile .preview .btn-play:hover {
  opacity: 0.8;
}
.video-block .profile h3 {
  font-size: 1em;
  line-height: 1.2857em;
  text-transform: uppercase;
  font-weight: normal;
  color: #9cc526;
  margin-bottom: 0;
}
.video-block .profile address {
  font-style: normal;
  margin-bottom: 0;
}
.video-block .profile .view {
  display: block;
}
.video-block .profile .view a {
  color: #303030;
}
.video-block .profile .view a:hover {
  text-decoration: underline;
  opacity: 0.8;
}
.video-block .more-profile-holder {
  padding-top: 21px;
}
.video-block .more-profile {
  display: block;
  text-align: center;
  border-top: 4px solid #cdebf7;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0 69px;
}
.video-block .more-profile a {
  color: #303030;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
}
.video-block .more-profile a .glyphicon {
  display: block;
}
.video-block .more-profile a:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 767px) {
  .buttons {
    text-align: left !important;
    margin-bottom: 20px;
  }
}
.buttons .btn {
  border-radius: 0;
  border-color: #9cc526;
  border-width: 2px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  min-width: 120px;
  padding: 15px 10px 11px;
}
.buttons .btn:hover {
  border-color: #b2d344;
}
.buttons .btn.btn-register {
  background: #9cc526;
  color: #fff;
}
.buttons .btn.btn-register:hover {
  background: #b2d344;
}
.buttons .btn.btn-login {
  background: #fff;
  color: #9cc526;
}
.buttons .btn.btn-login:hover {
  color: #b2d344;
}
@media only screen and (max-width: 767px) {
  .buttons .btn {
    font-size: 14px;
    line-height: 14px;
    padding: 10px;
  }
}

/* About */
.full-width{
  width:100%;
}
.about ul{
  padding-left:15px;
  margin:15px 0;
}
.explore-sec{
  background:#0170c9;
  margin:50px 0;

}
.explore-sec .container{
  background:url("/images/bg-slider.png") no-repeat 50% 0;
  background-size:cover;
  padding-top:29px;
  padding-bottom:29px;
}
.explore-sec .container .features{
  padding: 10px 80px 0 0;
}
.explore-sec .container h2,
.explore-sec .container h3,
.explore-sec .container li,
.explore-sec .container .btn-primary{
  color:#fff;
}
.explore-sec .container h2{
  line-height:30px;
}
.explore-sec .container h3{
  font-weight:400;
  margin:0 0 30px;
}
.explore-sec .container ol,.explore-sec .container ul{
  margin:0 0 20px;
  font-weight:600;
  padding:0 0 0 15px;
}
.explore-sec .container h3 strong{
  font-weight:600;
}
@media(max-width:767px) {
.explore-sec .container .features{
  padding-right: 0;
}
}

/* Workshops form design */
#res-workshops div {
    margin-left: 0;
    padding-left: 0;
}
#res-workshops label.checkbox {
    margin-left: 20px;
}
#res-workshops label.checkbox input {
    margin-top: 1px
}
#res-workshops div textarea {
    border-width: 2px;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    color: #303030;
    resize: none;
}
#res-workshops div input[type="submit"] {
    border-radius: 0;
    border-width: 2px;
    border-color: #3fa4f0;
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 13px 29px;
}

.body-class-archive #twocolumns,
.body-class-page #twocolumns,
.body-class-single #twocolumns {
    background-image: url("/wp-content/themes/blu/images/bg-cloud40.png");
}

.body-class-single #twocolumns .content {
    padding-left: 0;
    padding-right: 3em;
}

.body-class-archive #twocolumns #content p,
.body-class-page #twocolumns #content p,
.body-class-single #twocolumns #content p,
.body-class-archive #twocolumns #content ol li,
.body-class-page #twocolumns #content ol li,
.body-class-single #twocolumns #content ol li,
.body-class-archive #twocolumns #content ul li,
.body-class-page #twocolumns #content ul li,
.body-class-single #twocolumns #content ul li {
  color: black;
  line-height: 1.5em;
  letter-spacing: 0.3px;
}


/*# sourceMappingURL=style.css.map */


/** Jake Noble **/

.nav.navbar-nav li.sign-in-call-out  > a {
  border: none !important;;
  color: #23313a;
}

.nav.navbar-nav li.sign-in-call-out {
  margin-left: 40px;
  border: 1px solid #23313a !important;;
  border-radius: 4px;
}

.nav.navbar-nav li.sign-in-call-out:hover a {
  color: #aeaeae !important;;
}

.nav.navbar-nav li.sign-in-call-out:hover {
  border: 1px solid #23313a !important;
}

.nav.navbar-nav li.sign-in-call-out.active a,
.nav.navbar-nav li.sign-in-call-out:focus a,
.nav.navbar-nav li.sign-in-call-out:active a,
.nav.navbar-nav li.sign-in-call-out:hover a {
  border: none;
  color: #23313a;
}

.wpcf7-display-none {
  display: block !important;
}










