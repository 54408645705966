/*
Theme Name: BLU
Author: Anonymous
Author URI:
Version: 1
Description: Base theme for Wordpress
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: base
Tags: one-column, two-columns
Theme URI:
*/

@import "vendors/bootstrap";
@import "old-styles/style";
@import "old-styles/theme";
@import "old-styles/aug-2017";
@import "old-styles/new-home";
@import "old-styles/langs";
@import "old-styles/mc";
@import "old-styles/my_profile";
@import "old-styles/profile_dropdown";


// Grid System
@import "vendors/grids-min";
@import "vendors/grids-responsive-min";

// Pages
@import "pages/homepage-2019.scss"
